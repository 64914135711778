import request from '@/utils/request'

export function add(orderId, data) {
  return request({
    url: "api/shop/form/pay/" + orderId,
    method: "post",
    data
  })
}

export function edit(orderId, data) {
  return request({
    url: "api/shop/form/pay/" + orderId,
    method: "put",
    data
  })
}

export function del(payId) {
  return request({
    url: "api/shop/form/pay/" + payId,
    method: "delete"
  })
}

export function audit(data) {
  return request({
    url: "api/shop/form/pay/finCheck",
    method: "post",
    data
  })
}

export function rollbackAudit(payId) {
  return request({
    url: "api/shop/form/pay/finCheckRollBack",
    method: "post",
    data: {
      id: payId
    }
  })
}