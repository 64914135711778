<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="收/退款详情" width="700px" @closed="form = null;">
    <template v-if="form">
      <el-form :model="form" label-position="right" label-suffix="：" label-width="76px">
        <div class="h s">
          <div style="width: 50%; margin-right: 20px;">
            <template v-if="form.amount >= 0">
              <form-info-item label="收款单号">{{form.code}}</form-info-item>
              <form-info-item label="收款金额">
                <b class="fs-large fc-s">{{$price(form.amount)}}</b>
              </form-info-item>
              <form-info-item label="收款日期">{{new Date(form.payTime).format('yyyy/MM/dd')}}</form-info-item>
              <form-info-item label="收款渠道">{{payChannels[form.payChannel]}}</form-info-item>
            </template>
            <template v-else>
              <form-info-item label="退款单号">{{form.code}}</form-info-item>
              <form-info-item label="退款金额">
                <b class="fs-large fc-e">{{$price(Math.abs(form.amount))}}</b>
              </form-info-item>
              <form-info-item label="退款日期">{{new Date(form.payTime).format('yyyy/MM/dd')}}</form-info-item>
              <form-info-item label="退款渠道">{{payChannels[form.payChannel]}}</form-info-item>
            </template>
            <form-info-item label="支付方式">{{form.payType}}</form-info-item>
            <form-info-item label="支付票据">
              <file-uploader :entity-id="form.fileId" folder="pay/bill" multiple readonly empty-text="没有支付票据" />
            </form-info-item>
            <form-info-item label="是否审核">
              <dot :type="form.finCheck ? 'success' : 'gray'" same>{{form.finCheck ? '已审核' : '未审核'}}</dot>
            </form-info-item>
            <template v-if="form.finCheck">
              <form-info-item label="审核人">{{form.finCheckBy}}</form-info-item>
              <form-info-item label="审核日期">{{new Date(form.finCheckTime).format("yyyy/MM/dd")}}</form-info-item>
            </template>
            <form-info-item label="是否到账">
              <dot :type="form.inAccount ? 'success' : 'gray'" same>{{form.inAccount ? '已到账' : '未到账'}}</dot>
            </form-info-item>
            <form-info-item label="到账日期" v-if="form.inAccount">{{new Date(form.inAccountTime).format("yyyy/MM/dd")}}</form-info-item>
            <form-info-item label="是否回款">
              <dot :type="form.toAccount ? 'success' : 'gray'" same>{{form.toAccount ? '已回款' : '未回款'}}</dot>
            </form-info-item>
            <form-info-item label="回款日期" v-if="form.toAccount">{{new Date(form.toAccountTime).format("yyyy/MM/dd")}}</form-info-item>
          </div>
          <div class="flex">
            <form-info-item label="合同号">{{form.contractNo}}</form-info-item>
            <form-info-item label="单据编号">{{form.formCode}}</form-info-item>
            <form-info-item label="订单时间" v-if="form.relationFormType==='sale'">{{form.purchaseTime? new Date(form.purchaseTime).format():''}}</form-info-item>
            <form-info-item label="订单金额" v-if="form.relationFormType==='sale'">{{$price(form.totalMoney)}}</form-info-item>
            <form-info-item label="客户名称">{{form.customerName}}</form-info-item>
            <form-info-item label="客户电话">{{form.customerPhone}}</form-info-item>
            <form-info-item label="客户地址">{{form.customerAddress}}</form-info-item>
            <form-info-item v-if="$entType.get()==='kingkoil'" :label="form.inoutType === 'in' ? '收款凭证' : '退款凭证'">{{form.voucherNo}}</form-info-item>
            <form-info-item label="　　备注">{{form.remark}}</form-info-item>
          </div>
        </div>
      </el-form>

      <template v-if="action === 'audit' && !form.finCheck">
        <el-divider content-position="left">审核</el-divider>
        <el-form :model="form" label-position="right" label-suffix=":" label-width="74px">
          <div class="h e">
            <el-form-item label="审核日期">
              <el-date-picker v-model="auditDate" :clearable="false" value-format="timestamp" :picker-options="datePickerOptions" style="width: 180px;" />
            </el-form-item>
            <el-form-item label-width="10px">
              <el-button type="primary" :loading="submiting" @click="doAudit">提交审核</el-button>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template v-else-if="action === 'confirm' &&  form.finCheck && !form.inAccount">
        <el-divider content-position="left">确认</el-divider>
        <el-form :model="form" label-position="right" label-suffix=":" label-width="74px">
          <div class="h e">
            <el-form-item label="到账日期">
              <el-date-picker v-model="inDate" :clearable="false" value-format="timestamp" :picker-options="datePickerOptions" style="width: 180px;" />
            </el-form-item>
            <el-form-item label-width="10px">
              <el-button type="primary" :loading="submiting" @click="doInAccount">确认到账</el-button>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template v-else-if="action === 'returnMoney' && form.finCheck">
        <el-divider content-position="left">确认</el-divider>
        <el-form :model="form" label-position="right" label-suffix=":" label-width="74px">
          <div class="h e">
            <el-form-item label="回款日期">
              <el-date-picker v-model="toAccountDate" :clearable="false" value-format="timestamp" :picker-options="datePickerOptions" style="width: 180px;" />
            </el-form-item>
            <el-form-item label-width="10px">
              <el-button type="primary" :loading="submiting" @click="doToAccount">确认回款</el-button>
            </el-form-item>
          </div>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>
      
      
<script>
import request from "@/utils/request";

export default {
  data() {
    let now = new Date();
    return {
      submiting: false,
      dialog: false,
      form: null,
      auditDate: now.getTime(),
      inDate: now.getTime(),
      toAccountDate: now.getTime(), //回款日期

      payChannels: {
        mall: "商场",
        store: "POS",
        enterprise: "企业",
        app: "APP",
      },
      relationFormType: {
        sale: "销售单",
        back: "退货单",
      },
      datePickerOptions: {
        disabledDate: function (value) {
          return value > now;
        },
      },
    };
  },
  methods: {
    doAudit() {
      if (this.form && this.form.id) {
        this.$confirm(
          `${this.form.amount < 0 ? "退款" : "收款"}金额：<b class="${
            this.form.amount < 0 ? "fc-e" : "fc-s"
          }">${this.$price(this.form.amount)}</b><br />确定要对此项${
            this.form.amount < 0 ? "退款" : "收款"
          }进行审核？`,
          "操作确认",
          { type: "warning", dangerouslyUseHTMLString: true }
        ).then((_) => {
          this.submiting = true;
          request({
            url: "api/shop/form/pay/finCheck",
            method: "post",
            data: {
              id: this.form.id,
              finCheckTime: this.auditDate,
            },
          })
            .then((res) => {
              this.$notify({
                title: "审核成功",
                type: "success",
                duration: 2500,
              });
              this.$parent.init();
              this.dialog = false;
            })
            .finally((_) => {
              this.submiting = false;
            });
        });
      }
    },
    doInAccount() {
      if (this.form && this.form.id) {
        this.$confirm(
          `${this.form.amount < 0 ? "退款" : "收款"}金额：<b class="${
            this.form.amount < 0 ? "fc-e" : "fc-s"
          }">${this.$price(this.form.amount)}</b><br />确定要对此项${
            this.form.amount < 0 ? "退款" : "收款"
          }进行确认？`,
          "操作确认",
          { type: "warning", dangerouslyUseHTMLString: true }
        ).then((_) => {
          this.submiting = true;
          request({
            url: "api/shop/form/pay/confirm",
            method: "post",
            data: {
              id: this.form.id,
              inAccountTime: this.inDate,
            },
          })
            .then((res) => {
              this.$notify({
                title: "确认到账成功",
                type: "success",
                duration: 2500,
              });
              this.$parent.init();
              this.dialog = false;
            })
            .finally((_) => {
              this.submiting = false;
            });
        });
      }
    },
    doToAccount() {
      if (this.form && this.form.id) {
        this.$confirm("确定要对此项回款进行确认？", "操作确认", {
          dangerouslyUseHTMLString: true,
          type: "warning",
        }).then((_) => {
          this.submiting = true;
          request({
            url: "api/shop/form/pay/toAccount",
            method: "post",
            data: {
              id: this.form.id,
              toAccountTime: this.toAccountDate,
            },
          })
            .then((res) => {
              this.$notify({
                title: "确认回款成功",
                type: "success",
                duration: 2500,
              });
              this.$parent.init();
              this.dialog = false;
            })
            .finally((_) => {
              this.submiting = false;
            });
        });
      }
    },
    resetForm(form, action = false) {
      if (form) {
        this.form = form;
        this.action = action;
        this.dialog = true;
      }
    },
  },
};
</script>