<template>
  <div class="app-container v">
    <div class="head-container">
      <el-input v-model.trim="query.salesmanName" clearable placeholder="输入销售人员搜索" class="filter-item" style="width: 180px" @keyup.enter.native="reQuery" />
      <quick-select v-model="query.shopId" filterable url="api/shop" placeholder="选择门店" display-field="shopName" clearable class="filter-item" @change="handleChangeShop" style="width: 240px;" />
      <quick-select v-model="query.salesmanId" class="filter-item" v-if="query.shopId" filterable clearable style="width: 150px;" :url="`api/users?shopId=${query.shopId}`" value-field="id" display-field="userRealName" placeholder="销售人员" @change="reQuery" />
      <el-date-picker v-model="query.dateRange" type="daterange" clearable class="filter-item" range-separator="至" start-placeholder="收款日期起始" end-placeholder="收款日期截止" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="reQuery" style="width: 240px;" />
      <el-select v-model="query.payChannel" filterable clearable placeholder="收款渠道" @change="reQuery" class="filter-item" style="width: 120px;">
        <el-option v-for="(v, k) in payChannels" :key="k" :value="k" :label="v" />
      </el-select>
      <quick-select v-model="query.payTypeId" filterable clearable url="api/shopPayType" :params="{sort: 'displayIndex,asc'}" placeholder="支付方式" value-field="id" class="filter-item" @change="reQuery" style="width: 120px" />
      <el-select v-model="query.inoutType" filterable clearable placeholder="收支方向" @change="reQuery" class="filter-item" style="width: 120px;">
        <el-option v-for="(value,key) in inoutTypes" :key="key" :label="value" :value="key" />
      </el-select>
      <el-select v-model="query.feeType" placeholder="费用类型" @change="reQuery" class="filter-item" style="width: 120px;" clearable>
        <el-option v-for="(value, key) in feeTypes" :key="key" :label="value" :value="key" />
      </el-select>
      <el-select v-model="query.finCheck" filterable clearable placeholder="审核状态" @change="reQuery" class="filter-item" style="width: 120px">
        <el-option :value="true" label="已审核" />
        <el-option :value="false" label="未审核" />
      </el-select>
      <el-select v-model="query.inAccount" filterable clearable placeholder="到账状态" @change="reQuery" class="filter-item" style="width: 120px">
        <el-option :value="true" label="已到账" />
        <el-option :value="false" label="未到账" />
      </el-select>
      <el-select v-model="query.toAccount" filterable clearable placeholder="回款状态" @change="reQuery" class="filter-item" style="width: 120px">
        <el-option :value="true" label="已回款" />
        <el-option :value="false" label="未回款" />
      </el-select>
      <el-select v-model="query.sendNotice" filterable clearable placeholder="订单上报" @change="reQuery" class="filter-item" style="width: 120px">
        <el-option :value="true" label="已上报" />
        <el-option :value="false" label="未上报" />
      </el-select>
      <el-input v-model="query.customerName" :maxlength="20" class="filter-item" placeholder="客户名称搜索" style="width: 120px;" />

      <el-input v-model="query.formCode" :maxlength="20" class="filter-item" placeholder="销售单号/合同号搜索" style="width: 180px;" />
      <el-input v-model="query.remark" :maxlength="20" class="filter-item" placeholder="备注搜索" style="width: 120px;" />
      <!-- <el-input v-model="query.formCode" :maxlength="20" class="filter-item" placeholder="" style="width: 180px;" /> -->
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="reQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="reQuery">刷新</el-button>
    </div>
    <el-card shadow="never">
      <div class="h">
        <div class="money-count-item" v-show="!params.payChannel">
          <div class="fc-g">收款总额</div>
          <counter class="num" :end-val="(count.mallAmount + count.shopAmount + count.entAmount + count.appAmount) / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item" v-show="!params.payChannel || params.payChannel === 'mall'">
          <div class="fc-g">商场收款</div>
          <counter class="num" :end-val="count.mallAmount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item" v-show="!params.payChannel || params.payChannel === 'store'">
          <div class="fc-g">POS收款</div>
          <counter class="num" :end-val="count.shopAmount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item" v-show="!params.payChannel || params.payChannel === 'enterprise'">
          <div class="fc-g">企业收款</div>
          <counter class="num" :end-val="count.entAmount / 100" :decimals="2" :duration="1000" />
        </div>
        <div class="money-count-item" v-show="!params.payChannel || params.payChannel === 'app'">
          <div class="fc-g">APP收款</div>
          <counter class="num" :end-val="count.appAmount / 100" :decimals="2" :duration="1000" />
        </div>
        <!-- <div class="money-count-item sep"></div>
        <div class="money-count-item">
          <div class="fc-g">订单数量</div>
          <counter class="num" :end-val="count.orderFormCount" :duration="1000" />
        </div>
        <div class="money-count-item">
          <div class="fc-g">订单总金额</div>
          <counter class="num" :end-val="count.sumTotal / 100" :decimals="2" :duration="1000" />
        </div>-->
      </div>
    </el-card>
    <el-table v-loading="loading" row-key="id" :data="data" empty-text="未找到收款信息" border @sort-change="handleSort" style="margin-top: 12px;" height="100px">
      <el-table-column label="单据类型" width="100" :formatter="r => {return relationFormType[r.relationFormType]; }" prop="relationFormType" sortable="custom" />
      <el-table-column prop="feeType" label="费用类型" :formatter="r => { return feeTypes[r.feeType] }" width="100" sortable="custom" />
      <el-table-column prop="shopName" label="门店" min-width="200" show-overflow-tooltip sortable="custom" />
      <el-table-column label="支付日期" min-width="100" :formatter="r => { return new Date(r.payTime).format('yyyy/MM/dd'); }" prop="payTime" sortable="custom" />
      <el-table-column prop="code" label="收款单号" width="130" sortable="custom" />
      <el-table-column prop="voucherNo" label="收款凭证" width="100" sortable="custom" v-if="$entType.get()==='kingkoil'" />
      <el-table-column label="支付金额" min- width="120" align="right" prop="amount" sortable="custom">
        <template slot-scope="scope">
          <b :class="scope.row.amount < 0 ? 'fc-e' : 'fc-s'">{{$price(scope.row.amount)}}</b>
        </template>
      </el-table-column>
      <el-table-column prop="totalMoney" label="订单金额" min-width="120" align="right" sortable="custom">
        <template slot-scope="scope">
          <b :class="scope.row.amount < 0 ? 'fc-e' : 'fc-s'">{{$price(scope.row.totalMoney)}}</b>
        </template>
      </el-table-column>
      <el-table-column prop="payChannel" label="渠道" min-width="100" :formatter="r => {return payChannels[r.payChannel]; }" sortable="custom" />
      <el-table-column label="支付方式" min-width="100" prop="payType" sortable="custom" />

      <el-table-column prop="formCode" label="订单号" width="130" sortable="custom" />
      <el-table-column prop="orderFormStatus" label="订单状态" width="95" sortable="custom">
        <template slot-scope="scope">
          <el-tag :type="orderFormStatus[scope.row.orderFormStatus].type" v-if="orderFormStatus[scope.row.orderFormStatus]">{{orderFormStatus[scope.row.orderFormStatus].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="sendNotice" label="订单上报" align="center" width="95" sortable="custom">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.sendNotice"></i>
        </template>
      </el-table-column>
      <el-table-column prop="contractNo" label="合同号" width="160" sortable="custom" />
      <el-table-column prop="isPrepare" label="预售订单" align="center" width="100" sortable="custom">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.isPrepare"></i>
        </template>
      </el-table-column>
      <el-table-column prop="salesmanName" label="销售人员" min-width="100" sortable="custom" />
      <el-table-column prop="customerName" label="客户名称" min-width="100" sortable="custom" />
      <el-table-column prop="createAt" label="记录时间" width="150" :formatter="r => { return new Date(r.createAt).format(); }" sortable="custom" />
      <el-table-column prop="finCheckBy" label="审核人" width="100" />
      <el-table-column label="审核日期" width="100" :formatter="r => { return r.finCheckTime?new Date(r.finCheckTime).format('yyyy/MM/dd'):''; }" prop="finCheckTime" sortable="custom" />
      <el-table-column label="到帐日期" width="100" :formatter="r => { return r.inAccountTime?new Date(r.inAccountTime).format('yyyy/MM/dd'):''; }" prop="inAccountTime" sortable="custom" />
      <el-table-column label="回款日期" width="100" :formatter="r => { return r.toAccountTime?new Date(r.toAccountTime).format('yyyy/MM/dd'):''; }" prop="toAccountTime" sortable="custom" />

      <el-table-column label="备注" min-width="100" prop="remark" show-overflow-tooltip />
      <el-table-column label="已审核" width="90" align="center" fixed="right" prop="finCheck" sortable="custom">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.finCheck"></i>
        </template>
      </el-table-column>
      <el-table-column label="已到账" width="90" align="center" fixed="right" prop="inAccount" sortable="custom">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.inAccount"></i>
        </template>
      </el-table-column>
      <el-table-column label="已回款" width="90" align="center" fixed="right" prop="toAccount" sortable="custom">
        <template slot-scope="scope">
          <i class="fa fa-check" v-if="scope.row.toAccount"></i>
        </template>
      </el-table-column>

      <el-table-column width="250" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button type="text" size="mini" @click="edit(scope.row)">查看</el-button>
          <el-button v-permission="['FINANCE_ALL','FINANCE_MANAGER']" type="text" size="mini" @click="edit(scope.row, 'audit')" v-if="!scope.row.finCheck">审核</el-button>
          <el-button v-permission="['FINANCE_ALL','FINANCE_MANAGER']" type="text" size="mini" @click="edit(scope.row,'returnMoney')" v-if="scope.row.finCheck&&!scope.row.toAccount">回款</el-button>
          <el-button v-permission="['FINANCE_ALL','FINANCE_MANAGER']" type="text" size="mini" @click="redoRetrun(scope.row)" v-if="scope.row.finCheck&&scope.row.toAccount">取消回款</el-button>
          <el-button v-permission="['FINANCE_ALL','FINANCE_MANAGER']" type="text" size="mini" @click="redoInAccount(scope.row)" v-if="!$erp.enable() && scope.row.finCheck && scope.row.inAccount">取消到账</el-button>
          <template v-if="scope.row.finCheck && !scope.row.inAccount">
            <el-button v-permission="['FINANCE_ALL','FINANCE_MANAGER']" type="text" size="mini" @click="edit(scope.row, 'confirm')">到账</el-button>
            <el-button v-permission="['FINANCE_ALL','FINANCE_MANAGER']" type="text" size="mini" @click="redoAudit(scope.row)" class="danger">取消审核</el-button>
          </template>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import Counter from "vue-count-to";
import eForm from "./retail-form";
import request from "@/utils/request";
import { rollbackAudit } from "@/api/shopPay";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";

export default {
  mixins: [initData],
  components: { Counter, eForm },
  data() {
    let now = new Date();
    return {
      downloadLoading: false,
      queryable: false,
      inoutTypes: { in: "收款", out: "退款" },
      feeTypes: {
        orderForm: "订单费用",
        freight: "运费",
        repair: "维修费",
        rebate: "返利",
        other: "其他",
      },
      orderFormStatus: {
        input: { key: "input", label: "录入状态", type: "info" },
        submit: { key: "submit", label: "提交状态", type: "" },
        auditing: { key: "auditing", label: "审核中", type: "" },
        pass: { key: "pass", label: "审核通过", type: "success" },
        fail: { key: "fail", label: "审批未通过", type: "danger" },
        callback: { key: "callback", label: "退回修改", type: "info" },
        unsend: { key: "unsend", label: "待发货", type: "info" },
        sended: { key: "sended", label: "已发货", type: "success" },
        sendPart: { key: "sendPart", label: "部分已发货", type: "success" },
        cancel: { key: "cancel", label: "已取消", type: "info" },
        closed: { key: "closed", label: "已关闭", type: "info" },
        closedPart: { key: "closedPart", label: "部分已关闭", type: "info" },
      },
      query: {
        dateRange: [
          new Date(
            now.getFullYear(),
            now.getMonth() - 1,
            now.getDate(),
            0,
            0,
            0
          ).getTime(),
          new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            23,
            59,
            59
          ).getTime(),
        ],
        shopId: null,
        salesmanId: null,
        salesmanName: null,
        payChannel: null,
        inoutType: null,
        finCheck: null,
        inAccount: null,
        toAccount: null,
        formCode: null,
        payTypeId: null,
      },
      count: {
        mallAmount: 0,
        shopAmount: 0,
        entAmount: 0,
        appAmount: 0,
        orderFormCount: 0,
        items: [],
        sumTotal: 0,
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      payChannels: {
        mall: "商场",
        store: "POS",
        enterprise: "企业",
        app: "APP",
        change: "转移",
      },
      relationFormType: {
        sale: "销售单",
        back: "退货单",
      },
      sort: "createAt,desc",
    };
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/shop/formPayItems";
      let query = JSON.parse(JSON.stringify(this.query));
      //let required = false;
      //if (query.shopId) {
      if (query.formCode) {
        //required = true;
      } else if (query.dateRange && query.dateRange.length === 2) {
        //required = true;
        query.begTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      //}
      if (!query.payChannel) delete query.payChannel;
      //if (required) {
      query.sort = this.sort;
      this.params = query;
      // } else {
      //   this.$message.info("请选择门店，另外需要选择日期或者输入销售单号。");
      // }
      //this.queryable = required;
      this.queryable = true;
      return true;
    },
    calcCount() {
      if (this.queryable && this.params) {
        request({
          url: "api/shop/formPayItems/sum",
          method: "get",
          params: this.params,
        }).then((res) => {
          this.count = res;
        });
      }
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },
    reQuery() {
      this.current = null;
      this.toQuery();
      this.calcCount();
    },
    handleChangeShop() {
      this.query.salesmanId = null;
      this.current = null;
      this.toQuery();
      this.calcCount();
    },
    edit(row, action = null) {
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(row)), action);
    },

    redoAudit(data) {
      this.$confirm("您确定要取消该对账明细项的审核吗？", "操作确认", {
        type: "warning",
      }).then((_) => {
        rollbackAudit(data.id).then((res) => {
          this.$notify({
            title: "取消审核成功",
            type: "success",
            duration: 2500,
          });
          this.init();
        });
      });
    },
    redoInAccount(data) {
      this.$confirm("您确定要取消该对账明细项的到账吗？", "操作确认", {
        type: "warning",
      }).then((_) => {
        request({
          url: "api/shop/form/pay/inAccount/rollback",
          method: "post",
          data: {
            id: data.id,
          },
        }).then((res) => {
          this.$notify({
            title: "取消到账成功",
            type: "success",
            duration: 2500,
          });
          this.init();
        });
      });
    },
    // 取消回款
    redoRetrun(data) {
      this.$confirm("您确定要取消该对账明细项的回款吗？", "操作确认", {
        type: "warning",
      }).then((_) => {
        request({
          url: "api/shop/form/pay/toAccount/rollback",
          method: "post",
          data: {
            id: data.id,
          },
        }).then((res) => {
          this.$notify({
            title: "取消回款成功",
            type: "success",
            duration: 2500,
          });
          this.init();
        });
      });
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/shop/formPayItems/download", this.params)
        .then((result) => {
          downloadFile(result, "零售对账", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
  mounted() {
    this.reQuery();
    // const end = new Date();
    // const start = new Date();
    // start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    // this.query.dateRange = [start.getTime(), end.getTime()];
  },
};
</script>